import styled, { css } from 'styled-components';

export const Wrapper = styled.header`
  ${({ theme }) => css`
    background: ${theme.color.primary};
    padding: 1.2rem 6%;
  `}
`;

export const Content = styled.div<{ isHomePage: boolean }>`
  ${({ isHomePage }) => css`
    width: 100%;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: ${isHomePage ? '85.5rem' : ''};
  `}
`;

export const Menu = styled.nav<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    button {
      display: none;
    }

    @media (max-width: 800px) {
      > button {
        display: flex;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        cursor: pointer;
        > span {
          border-top: 2px solid;
          width: 20px;
          display: block;
          color: ${theme.color.white};
          border-top-color: ${isActive ? 'transparent' : ''};
          position: relative;

          &:after,
          &:before {
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            background-color: currentColor;
            margin-top: 5px;
            transition: 0.3s;
            position: relative;
          }

          &:before {
            transform: ${isActive ? 'rotate(135deg)' : ''};
          }

          &:after {
            transform: ${isActive ? 'rotate(-135deg)' : ''};
            top: ${isActive ? '-7px' : ''};
          }
        }
      }
    }
  `}
`;

export const MenuList = styled.ul<{
  isActive: boolean;
  isAuthenticated: boolean;
}>`
  ${({ theme, isActive, isAuthenticated }) => css`
    /* display: flex;
    align-items: center;
    gap: 4rem; */

    li {
      display: inline-block;

      + li {
        margin-left: 4rem;
      }

      a {
        color: ${theme.color.white};
        font-size: 1.4rem;
      }
    }

    @media (max-width: 800px) {
      display: block;
      position: absolute;
      width: 100%;
      top: 56px;
      right: 0;
      background-color: ${theme.color.primary};
      height: ${isActive ? 'calc(100vh - 56px)' : 0};
      z-index: 1000;
      transition: 0.5s;
      visibility: ${isActive ? 'visible' : 'hidden'};
      overflow-y: ${isActive ? 'auto' : 'hidden'};

      display: flex;
      flex-direction: column;

      > li {
        + li {
          margin-left: unset;
        }

        padding: 1.6rem 0;
        margin: 0 1rem;
        border-bottom: 2px solid rgba(255, 255, 255, 0.25);
        text-align: center;

        > a {
          font-size: 2rem;
        }

        :last-child {
          display: ${isAuthenticated ? 'none' : ''};
        }
      }
    }
  `}
`;

export const NoStyleButton = styled.button`
  ${({ theme }) => css`
    display: initial !important;
    color: ${theme.color.white};
    font-size: 1.4rem;
  `}
`;

export const NoStyleMobileButton = styled(NoStyleButton)`
  ${({ theme }) => css`
    font-size: 2rem;
  `}
`;

export const ProfileListBackgroundToGetClick = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) => css`
    display: ${isOpen ? 'initial' : 'none'};

    position: fixed;
    width: 100vw;
    height: 100vh;
    /* background-color: black; */
    cursor: auto;
    z-index: 1;

    left: 0;
    top: 0;
  `}
`;

export const ProfileList = styled.ul<{ isOpen: boolean }>`
  ${({ theme, isOpen }) => css`
    display: ${isOpen ? 'initial' : 'none'};

    cursor: auto;
    z-index: 2;

    background-color: ${theme.color.primary};
    padding: 0.8rem 1.2rem 0.8rem 1.2rem;
    border-radius: 1px;
    width: 16rem;

    position: absolute;
    transform: translate(-95%, 55px);

    li:not(:first-child) {
      margin-top: 1.6rem;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 10%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: ${theme.color.primary};
      border-top: 0;
      margin-left: -10px;
      margin-top: -10px;
    }
  `}
`;

export const MobileHeaderLink = styled.span`
  a {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: #fff;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
`;

export const MobileHeaderLinkSeparator = styled.div`
  height: 45px;
  border-left: 1px solid #fff;
  margin: 0 1rem;
`;
