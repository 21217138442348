import { useEffect, useState } from 'react';

import * as S from './style';

export function UseTerms() {
  const [isTermsAccepted, setIsTermsAccepted] = useState(true);
  function handleAcceptTerms() {
    setIsTermsAccepted(true);
    localStorage.setItem('@boratickets:isTermsAccepted', 'true');
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const termsFromLocalStorage = JSON.parse(
        localStorage.getItem('@boratickets:isTermsAccepted'),
      );
      if (termsFromLocalStorage) {
        setIsTermsAccepted(true);
      } else {
        setIsTermsAccepted(false);
      }
    }
  }, []);

  return (
    !isTermsAccepted && (
      <>
        <S.Container>
          <p>
            O site do Bora Tickets utiliza termos de uso, privacidade e política
            de compra para melhorar sua experiência de navegação em nossos
            serviços e recomendar nossos eventos de seu interesse. Ao continuar
            a utilizar nossos serviços, você concorda com nossos{' '}
            <a
              href="documents/Termo_de_uso_BORA_TICKETS._29.06.2021.pdf"
              target="_blank"
            >
              Termos de uso
            </a>
            ,{' '}
            <a
              href="documents/Politica_de_Privacidade__BORA_TICKETS._29.06.2021.pdf"
              target="_blank"
            >
              privacidade
            </a>{' '}
            e{' '}
            <a href="documents/politica_compras.pdf" target="_blank">
              política de compras
            </a>
            .
          </p>
          <S.Button onClick={handleAcceptTerms}>Aceito</S.Button>
        </S.Container>
      </>
    )
  );
}
