/* eslint-disable react/no-danger */
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

import { useAuth } from '@/contexts/auth';
import { useMatchMedia } from '@/hooks/useMatchMedia';
import { TitleHidden } from '@/styles';
import { getFirstName } from '@/utils';

import * as S from './styles';

const AuthModal = dynamic(() => import('@/components/AuthModal'), {
  ssr: false,
});

const Header = ({ isHomePage = false, isAuthModalOpen = false }) => {
  const { isAuthenticated, clientData, handleLogout } = useAuth();
  const [openModalAuth, setOpenModalAuth] = useState(isAuthModalOpen);
  const [isMobile, setIsMobile] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const router = useRouter();

  const isScreenLessThan800 = useMatchMedia('(max-width: 800px)');

  const handleOpenModalAuth = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (isAuthenticated) {
        return;
      }

      event.preventDefault();
      setOpenModalAuth(true);
    },
    [isAuthenticated],
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth <= 800) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, []);

  useEffect(() => {
    function thereIsNameAndLastName(value: string) {
      const valueArray = value.trim().split(' ');
      return valueArray.length > 1;
    }

    function redirectToProfileIfUserHasNoNameOrLastName() {
      if (
        clientData &&
        (!clientData?.nomeCliente ||
          !thereIsNameAndLastName(clientData?.nomeCliente || '') ||
          !clientData?.dataNascimentoCliente ||
          !clientData?.telefoneCliente ||
          clientData?.emailConfirmadoCliente === 'NAO' ||
          !clientData?.enderecoCepCliente) &&
        !router.asPath.includes('/profile') &&
        !router.asPath.includes('/validate-email')
      ) {
        setTimeout(() => {
          router.push('/profile');
        }, 500);
      }
    }
    redirectToProfileIfUserHasNoNameOrLastName();
  }, [clientData, isAuthenticated, router]);

  return (
    <S.Wrapper>
      <S.Content isHomePage={isHomePage}>
        {!isScreenLessThan800 && (
          <a href="/">
            <picture>
              <img src="/images/bora-logo-white.svg" alt="Logo" />
            </picture>
          </a>
        )}

        <S.Menu isActive={mobileMenuActive}>
          <TitleHidden>Navegação principal</TitleHidden>

          <button
            onTouchStart={() => setMobileMenuActive(!mobileMenuActive)}
            type="button"
            aria-expanded={mobileMenuActive ? 'true' : 'false'}
            aria-haspopup="true"
            aria-controls="menu"
            aria-label={mobileMenuActive ? 'Fechar Menu' : 'Abrir Menu'}
          >
            <span />
          </button>

          <S.MenuList
            id="menu"
            role="menu"
            isActive={mobileMenuActive}
            isAuthenticated={isAuthenticated}
          >
            <li>
              <a href="/">Organizadores</a>
            </li>

            <li>
              <a href="/quem-somos">Quem Somos</a>
            </li>

            <li>
              <a href="/central-de-ajuda">Central de Ajuda</a>
            </li>

            <li>
              <a href="/eventos">Eventos</a>
            </li>

            <li>
              <a
                href={`${isAuthenticated ? '/painel' : '/'}`}
                onClick={handleOpenModalAuth}
              >
                {isAuthenticated ? `Meus ingressos` : 'Login'}
              </a>
            </li>

            {isAuthenticated && isMobile && (
              <>
                <li>
                  <a href="/profile">Minha Conta</a>
                </li>
                <li>
                  <S.NoStyleMobileButton type="button" onClick={handleLogout}>
                    Sair
                  </S.NoStyleMobileButton>
                </li>
              </>
            )}

            {isAuthenticated && (
              <li>
                <S.NoStyleButton
                  type="button"
                  onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                >
                  {getFirstName(clientData.nomeCliente) || 'Minha Conta'}
                </S.NoStyleButton>

                <S.ProfileList isOpen={isProfileMenuOpen}>
                  <li>
                    <a href="/profile">Minha Conta</a>
                  </li>

                  <li>
                    <S.NoStyleButton type="button" onClick={handleLogout}>
                      Sair
                    </S.NoStyleButton>
                  </li>
                </S.ProfileList>

                <S.ProfileListBackgroundToGetClick
                  isOpen={isProfileMenuOpen}
                  onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                />

                <script
                  dangerouslySetInnerHTML={{
                    __html: `
                    var customerID = "${clientData.codCliente}";
                    (function() {
                      var period = 300;
                      var limit = 20 * 1e3;
                      var nTry = 0;
                      var intervalID = setInterval(function() {
                        var clear = limit/period <= ++nTry;
                        if ((typeof(Konduto) !== "undefined") &&
                            (typeof(Konduto.setCustomerID) !== "undefined")) {
                        window.Konduto.setCustomerID(customerID);
                        clear = true;
                      }
                      if (clear) {
                    clearInterval(intervalID);
                    }
                    }, period);
                    })(customerID);
                    `,
                  }}
                />
              </li>
            )}
          </S.MenuList>
        </S.Menu>

        {isScreenLessThan800 && (
          <>
            <a href="/">
              <picture>
                <img src="/images/bora-logo-white.svg" alt="Logo" />
              </picture>
            </a>

            <S.MobileHeaderLinkSeparator />

            <S.MobileHeaderLink>
              <a href="/central-de-ajuda">Central de Ajuda</a>
            </S.MobileHeaderLink>
          </>
        )}
      </S.Content>

      <AuthModal
        open={openModalAuth}
        onClose={() => {
          setOpenModalAuth(false);
          router.push('/');
        }}
      />
    </S.Wrapper>
  );
};

export default React.memo(Header);
