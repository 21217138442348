import Link from 'next/link';
import React from 'react';

import { ReactComponent as IconFacebookSvg } from '@/assets/icon-facebook-footer.svg';
import { ReactComponent as IconInstagramSvg } from '@/assets/icon-instagram-footer.svg';
import configClient from '@/config/client';
import { Container } from '@/styles';

import * as S from './styles';

const Footer = () => {
  return (
    <S.Wrapper>
      <Container>
        <S.Content>
          <S.Left>
            <S.LogoRed>
              <img src="/images/bora-logo-red.svg" alt={configClient.title} />
            </S.LogoRed>

            <div>
              <S.Checkout>Em breve:</S.Checkout>
              <S.Stores>
                <picture>
                  <a
                    href="https://apple.com.br"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    <img src="/images/apple-store.png" alt="App Store" />
                  </a>
                </picture>
                <picture>
                  <a
                    href="https://google.com"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    <img src="/images/google-store.png" alt="Google Store" />
                  </a>
                </picture>
              </S.Stores>
            </div>

            <S.SocialMedias>
              <p>Siga:</p>
              <ul>
                <li>
                  <a
                    href="https://instagram.com/boratickets?utm_medium=copy_link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconInstagramSvg />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <IconFacebookSvg />
                  </a>
                </li>
              </ul>
            </S.SocialMedias>
          </S.Left>

          <S.Right>
            <S.Nav>
              <ul>
                <li>
                  <ul>
                    <li>
                      <a href="/quem-somos">Quem Somos</a>
                    </li>
                    <li>Equipe</li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li>
                      <a href="/eventos">Eventos</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li>
                      <a href="/central-de-ajuda">Central de ajuda</a>
                    </li>
                    <li>contato@boratickets.com.br</li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li>
                      <a href="/">Contato</a>
                    </li>
                    <li>contato@boratickets.com.br</li>
                  </ul>
                </li>
              </ul>
            </S.Nav>
          </S.Right>
        </S.Content>
      </Container>

      <S.DevelopedBy>
        <p>Desenvolvido por</p>

        <a href="https://nvgo.com.br" target="_blank" rel="nofollow noreferrer">
          <picture>
            <img src="/images/nvgo-logo.png" alt="Nvgo" />
          </picture>
        </a>
      </S.DevelopedBy>
    </S.Wrapper>
  );
};

export default React.memo(Footer);
