import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #d61f14;
    color: ${theme.color.white};
    padding: 2rem;

    width: 70%;
    display: flex;
    align-items: center;

    a {
      color: white;
      text-decoration: underline;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    margin-left: 1.2rem;
    padding: 1.2rem 4rem;
    font-weight: bold;
    background-color: ${theme.color.white};
    color: ${theme.color.primary};

    @media (max-width: 768px) {
      margin: 2.4rem 0 0;
    }
  `}
`;
