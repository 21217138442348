import styled, { css } from 'styled-components';

export const Wrapper = styled.footer`
  ${({ theme }) => css`
    background: ${theme.color.white};
  `}
`;

export const Content = styled.div`
  ${() => css`
    padding: 7.2rem 0 4rem;
    display: flex;
    /* align-items: flex-start; */
    /* justify-content: center; */

    @media (max-width: 768px) {
      flex-direction: column;
    }
  `}
`;

export const Left = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding-right: 3.2rem;

    &:after {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      right: 0;
      top: 0;
      background: ${theme.color.primary};
    }
    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      text-align: center;
      padding-bottom: 1.2rem;
      margin-bottom: 1.2rem;

      :after {
        width: 100%;
        height: 2px;
        bottom: 0;
        top: auto;
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
      padding: 0;
      padding-bottom: 1.2rem;
    }
  `}
`;

export const LogoRed = styled.picture`
  ${() => css`
    display: block;
    margin-bottom: 2rem;
  `}
`;

export const Checkout = styled.p`
  ${({ theme }) => css`
    font-size: 1.4rem;
    font-family: ${theme.font.weight.regular};
    color: ${theme.color.primary};
    margin-bottom: 0.4rem;
  `}
`;

export const Stores = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
    margin-left: -1.2rem;

    > picture {
      width: 12.5rem;
    }

    @media (max-width: 768px) {
      flex-direction: row;
    }
  `}
`;

export const SocialMedias = styled.section`
  ${({ theme }) => css`
    margin-top: 1.4rem;
    display: flex;
    gap: 2rem;

    p {
      font-size: 1.4rem;
      font-family: ${theme.font.weight.regular};
      color: ${theme.color.primary};
    }

    ul {
      display: flex;
      gap: 1.2rem;
      li {
        svg {
          path {
            fill: ${theme.color.primary};
          }
        }
      }
    }
  `}
`;

export const Right = styled.div`
  ${() => css`
    padding-left: 3.2rem;
    @media (max-width: 768px) {
      padding: 0;
      margin: 0 auto;
      text-align: center;
    }
  `}
`;

export const Nav = styled.nav`
  ${({ theme }) => css`
    position: relative;

    font-size: 1rem;

    > ul {
      display: flex;
      gap: 3rem;
      align-items: flex-start;
      justify-content: center;

      > li {
        > ul {
          display: flex;
          flex-direction: column;
          gap: 1.4rem;
        }
      }

      a {
        color: ${theme.color.primary};
        text-decoration: none;
        font-size: 1.2rem;
      }

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;

        ul {
          align-items: center;
        }
      }
    }

    @media (max-width: 400px) {
      > ul {
        flex-direction: column;
      }
    }
  `}
`;

export const DevelopedBy = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1.6rem 0;
    background: ${theme.color.text};

    p {
      color: #b3b3b3;
      font-size: 1.1rem;
      font-weight: bold;
    }

    a {
      font-size: 0;

      img {
        width: 6.2rem;
        height: 1.7rem;
        position: relative;
        top: 0.2rem;
        margin-left: 1.5rem;
      }
    }
  `}
`;
