import React, { ReactNode } from 'react';

import Footer from '@/components/Footer';
import Header from '@/components/Header';
import configClient from '@/config/client';
import { TitleHidden } from '@/styles';

import SEO, { ISeoProps } from '../SEO';
import { UseTerms } from '../UseTerms';

type Props = {
  children: ReactNode;
  isHomePage?: boolean;
  isAuthModalOpen?: boolean;
  seo?: ISeoProps;
};

const Layout = ({ children, isHomePage, isAuthModalOpen, seo }: Props) => {
  return (
    <>
      <SEO {...seo} />
      <TitleHidden>{configClient.title}</TitleHidden>
      <Header isHomePage={isHomePage} isAuthModalOpen={isAuthModalOpen} />
      <main>{children}</main>
      <UseTerms />
      <Footer />
    </>
  );
};

export default React.memo(Layout);
