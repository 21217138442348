import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 90%;
  max-width: 85.5rem; // 855px
  margin: 0 auto;
  position: relative;
  display: block;

  @media (max-width: 500px) {
    width: 95%;
  }
`;

export const TitleHidden = styled.h1`
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
`;

type AspectRatioContentProps = {
  fixHeight?: string;
  aspectRatio?: number;
};

export const AspectRatioContent = styled.div<AspectRatioContentProps>`
  ${({ fixHeight, aspectRatio = 9 / 16 }) => css`
    width: 100%;
    overflow: hidden;
    position: relative;
    display: block;

    height: ${fixHeight || 'auto'};
    padding-top: ${fixHeight ? 'auto' : `${aspectRatio * 100}%`};

    box-shadow: inset 0 0 10px 0 rgb(0, 0, 0, 0.1);
    background: #cccccc;

    img {
      object-fit: cover;
    }

    iframe,
    img,
    object,
    video {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  `}
`;

export const BoxOverlay = styled.div`
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    z-index: 1;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0),
      rgba(0, 0, 0, 0.4) 40%,
      rgba(0, 0, 0, 0.1)
    );
  }
`;

export const TableWrapper = styled.table`
  ${({ theme }) => css`
    width: 100%;
    border-collapse: collapse;
    vertical-align: middle;
    border-spacing: 1px;

    thead {
      tr {
        color: ${theme.color.white};
        background: ${theme.color.primary};
      }

      th {
        padding: 1rem;
        text-align: left;
        font-size: 1.6rem;
        font-weight: ${theme.font.weight.medium};
      }
    }

    tbody {
      td {
        padding: 0.8rem 1.2rem;
        font-size: 1.4rem;
        position: relative;
      }

      tr {
        border: 1px solid ${theme.color.border};
        border-top: none;

        &:nth-child(even) {
          background: ${theme.color.border};
        }
      }
    }
  `}
`;
